// import store from '@/store/index';
import {GET_REQUEST} from '@/services/http';
import apiPath from '@/services/apiPath';
import poData from '@/constants/poData.js';


export default class CardMissingViewModel {
  constructor() {
    this.childUid = '';
    this.loaded= false;
    this.cardInfo = {
      card_status:'', // SUSPENDED (분실) || REGISTERED (정상등록)
      card_number:''
    };
    this.missingInfo = {
      ON:{
        title: poData.missing_a_card_direction,
        status:'on',
        statusText:'ON 사용가능',
        infoDataList:[
          {
            title:'분실신고 전, 확인!',
            desc:'<ul><li>분실신고하는 경우, 연결되어 있는 가족에게도 분실신고 현황이 공유돼요</li><li>퍼핀카드 사용은 즉시 중지되지만, 교통카드는 중지되지 않아요. 교통카드 중지는 퍼핀고객센터로 문의해주세요</li><li>분실/도난을 인지하고도 합리적인 이유 없이 늦게 신고하는 경우에는 보상 받지 못할 수 있으니 유의해 주세요</li></ul>',
          },
        ]
      },
      OFF:{
        title: poData.find_a_card_direction,
        status:'off',
        statusText:'OFF 분실신고',
        infoDataList:[
          {
            title:'분실신고 취소, 유의사항!',
            desc:'<ul><li>분실신고를 취소하면 정지되었던 퍼핀카드를 다시 사용할 수 있어요</li><li>퍼핀잔액의 안전한 보호를 위해 카드를 찾은 경우에만 분실신고를 취소해 주세요</li><li>분실신고를 취소하는 경우, 연결되어 있는 가족에게도 분실해제 현황이 공유돼요</li><li>분실/도난으로 카드를 찾을 수 없다면, 새 카드를 재발급해 주세요. 가족대표인 부모님 앱에서 가능해요</li></ul>'
          }
        ]
      },
    }
  }
  init(childUid){
    if(childUid){
      this.childUid = childUid;
    }
    this.getCardInfo();
  }
  getCardInfo(){
    let path = ''
    if(this.childUid){
      path = `${apiPath.CARD_LOSS_INFO_PARENTS.format(this.childUid)}`;
    }else{
      path = `${apiPath.CARD_LOSS_INFO_CHILD}`;
    }
    GET_REQUEST(path).then(
    (success) => {
      const resultData = success.data.body;
      this.loaded = true;
      this.cardInfo.card_status = resultData.card.status;
      this.cardInfo.card_number = resultData.card.masked_card_number;
    })
  }
}